import React from 'react'

const NetworkSVG = () => (
  <svg width="63px" height="62px" viewBox="0 0 63 62" version="1.1">
    <defs>
      <circle id="path-1" cx="9" cy="9" r="9" />
    </defs>
    <g
      id="Final"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="The-DataRobot-AI-Adoption-Path-2"
        transform="translate(-111.000000, -965.000000)"
      >
        <g id="Group-7-Copy-2" transform="translate(113.000000, 960.000000)">
          <g id="ds_democratization" transform="translate(0.000000, 6.000000)">
            <circle
              id="Oval-5"
              stroke="#2D8FE2"
              stroke-width="2"
              fill-opacity="0.6"
              fill="#C7E3FC"
              fill-rule="nonzero"
              cx="53"
              cy="7"
              r="7"
            />
            <circle
              id="Oval-5-Copy-3"
              stroke="#2D8FE2"
              stroke-width="2"
              fill-opacity="0.6"
              fill="#C7E3FC"
              fill-rule="nonzero"
              cx="30"
              cy="7"
              r="7"
            />
            <circle
              id="Oval-5-Copy-4"
              stroke="#2D8FE2"
              stroke-width="2"
              fill-opacity="0.6"
              fill="#C7E3FC"
              fill-rule="nonzero"
              cx="30"
              cy="53"
              r="7"
            />
            <circle
              id="Oval-5-Copy"
              stroke="#2D8FE2"
              stroke-width="2"
              fill-opacity="0.6"
              fill="#C7E3FC"
              fill-rule="nonzero"
              cx="53"
              cy="30"
              r="7"
            />
            <g id="Oval-5-Copy-5" transform="translate(0.500000, 21.000000)">
              <circle stroke="#2D8FE2" stroke-width="2" cx="9" cy="9" r="10" />
              <circle
                fill="#C7E3FC"
                fill-rule="nonzero"
                opacity="0.6"
                mask="url(#mask-2)"
                cx="9.0"
                cy="9"
                r="8.5"
              />
            </g>
            <circle
              id="Oval-5-Copy-2"
              stroke="#2D8FE2"
              stroke-width="2"
              fill-opacity="0.6"
              fill="#C7E3FC"
              fill-rule="nonzero"
              cx="53"
              cy="53"
              r="7"
            />
            <polygon
              id="Path-4"
              fill="#2D8FE2"
              fill-rule="nonzero"
              points="16.4565275 22.4132875 14.9756522 21.0690375 23.9942539 11.1338134 25.4751293 12.4780634"
            />
            <polygon
              id="Path-4"
              fill="#2D8FE2"
              fill-rule="nonzero"
              points="19.3722021 26.1390931 18.5132731 24.3329256 47.5509963 10.5239392 48.4099253 12.3301067"
            />
            <polygon
              id="Path-4"
              fill="#2D8FE2"
              fill-rule="nonzero"
              transform="translate(20.225391, 43.226450) scale(1, -1) translate(-20.225391, -43.226450) "
              points="16.4565275 48.8661866 14.9756522 47.5219366 23.9942539 37.5867125 25.4751293 38.9309625"
            />
            <polygon
              id="Path-4"
              fill="#2D8FE2"
              fill-rule="nonzero"
              transform="translate(33.461599, 41.668484) scale(1, -1) translate(-33.461599, -41.668484) "
              points="19.3722021 49.4760608 18.5132731 47.6698933 47.5509963 33.8609069 48.4099253 35.6670744"
            />
            <polygon
              id="Path-4-Copy-2"
              fill="#2D8FE2"
              fill-rule="nonzero"
              transform="translate(32.795591, 29.871412) scale(1, -1) translate(-32.795591, -29.871412) "
              points="19.7955906 30.8714123 19.7955906 28.8714123 45.7955906 28.8714123 45.7955906 30.8714123"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default NetworkSVG
