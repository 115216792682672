import React from "react";
import styled from "styled-components"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { LinkButton, PageLink, H2 } from '../css/stylecomp'
import Layout from "../components/layout";
import Section from "../components/section";
import TwitterFeed from "../components/twitter";
import Footer from "../components/footer";
import SEO from "../components/seo";
import NetworkSVG from "../components/svg/network";
import RocketSVG from "../components/svg/rocket";
import TodoSVG from "../components/svg/todo";

import BackgroundImage from 'gatsby-background-image'
// packages built with: NPM modules installed using yarn

// Free icons:
// https://feathericons.com/
// https://material.io/tools/icons/?icon=ballot&style=twotone
// http://glyph.smarticons.co
// https://icons8.com/icon/pack/free-icons/ultraviolet
// https://getbootstrap.com/docs/4.1/extend/icons/



const Hotspot = styled.div`
  position: absolute;
  border: 0px solid blue;
  top: ${props => props.top}; 
  left: ${props => props.left}; 
  height: ${props => props.height}; 
  width: ${props => props.width}; 
  & + div {
    pointer-events: none;
    opacity: 0;
    display: none;
  }
  &:hover + div {
      opacity: 1.0;
      display: block;
  }
  &:hover ~ span {
    opacity: 0;
    display: none;
}
`

const StyledBackgroundSection = styled(BackgroundImage)`
  height: 60vh;
  border: 1px solid #e6e6e6;
  background-position: bottom center;
  @media screen and (max-width: 768px) {
    height: 50vh;
  }
`;

{/* <div style={{ position: "relative"}}>
<div className="flex flex-col lg:w-3/4 text-left opacity-100" style={{ position: "absolute", top: '10vh', left: 0, zIndex: 2  }} >
    <H2 className="max-w-xl font-thin ">We're a <span className="text-blue font-medium">Data Science</span> company  focusing on quantification of unstructured data and Natural Language Processing.</H2>
    <LinkButton href="/contact"  className="mt-8 w-56">Tell us about your project</LinkButton>
</div>
<iframe style={{ position: "absolute", top: '10%', left: '20%', zIndex: 1, width: "100%", height: "50vh", opacity: 0.5 }} src='https://public.flourish.studio/visualisation/808513/embed' title='graph' frameborder='0' scrolling='no' ></iframe>
</div> 

<Section className="bg-very-lightest-grey border-solid border border-very-grey-light h-45vh md:h-60vh pt-8 pb-8 flex items-center " >
xl:w-3/4 px-4 ml-auto mr-auto
style={{height: '60vh', paddingTop: '2rem', paddingBottom: '2rem', flex: 1, flexDirection: 'col', alignItems: 'center'}} 
*/}


const IndexPage = (props) => {
  return (
    <Layout>
      <SEO
        title="dav|consulting"
        keywords={[`data`, `science`, `consulting`]}
      />

      <div>
        <StyledBackgroundSection Tag="section" fluid={props.data.desktop.childImageSharp.fluid}  >
          <Section className="border-solid border h-50vh md:h-60vh pt-8 pb-8 flex items-center " >

            <div className="flex flex-col lg:w-5/6 text-left pt-8 md:pb-6"  >
              {/* <H2 className="max-w-xl font-thin ">We're a <span className="text-blue font-medium">Data Science</span> company  focusing on quantification of unstructured data and Natural Language Processing.</H2> */}

              <H2 className="text-very-lightest-grey font-thin ">We're a <span className="text-my-light-yellow  font-medium">Data Science</span> company focusing on the cultivation and quantification of unstructured data for generating deeper evidence based insights.</H2>
              <LinkButton href="/contact" className="mt-8 w-56">Tell us about your project</LinkButton>
            </div>
          </Section>
        </StyledBackgroundSection>
        <Section>
          <div id="services" className="bg-white w-full px-4 pt-8 md:pt-24 pb-2 md:pb-10 text-center flex flex-col ">
            {/* <H2 className="font-thin">Our <b>purpose</b> is to assist our clients to develop data-driven processes and gain deeper evidence-based insights</H2> */}
            <div className="font-thin text-grey-darkest text-lg md:text-2xl">We assist our clients with various <span className="text-blue">data-driven</span> processes e.g. task automation, natural language processing, data wrangling, web-scraping and much more...</div>

            <p className="pt-8 md:text-lg font-thin text-grey-dark">Our services cover three main areas – data collection, data analysis and data visualization.</p>
          </div>
        </Section>
        <Section>
          <div className="flex flex-col md:flex-row md:pb-6">
            <div className="bg-white lg:w-1/3 flex flex-col">
              <div className="box flex flex-col  flex-1 border border-solid border-very-grey-light rounded-lg text-center xl:m-4 m-2 p-10 h-auto">
                <div className=""><TodoSVG /></div>
                <PageLink href="/contact" className="text-base font-semibold py-6">Data collection</PageLink>
                <p className="leading-loose py-4">Collect data for all your needs. We can help with survey based data collection, collection of unstructured web-data and meta data generation from most kinds of information sources. </p>
                <PageLink href="/contact" className="text-sm font-normal py-6">learn more...</PageLink>
              </div>
            </div>
            <div className="bg-white lg:w-1/3 flex flex-col">
              <div className="box flex flex-col flex-1 border border-solid border-very-grey-light rounded-lg text-center xl:m-4 m-2 p-10">
                <div className=""><RocketSVG /></div>
                <PageLink href="/contact" className="text-base font-semibold py-6">Data analysis</PageLink>
                <p className="leading-loose py-4">Make the best use of your data. We  specialize in statistical analysis, natural language processing and machine learning  and have a profound experience in modeling and prediction.
                </p>
                <PageLink href="/contact" className="text-sm font-normal py-6">learn more...</PageLink>
              </div>
            </div>
            <div className="bg-white lg:w-1/3 flex flex-col">
              <div className="box flex flex-col flex-1 border border-solid border-very-grey-light rounded-lg text-center xl:m-4 m-2 p-10">
                <div className=""><NetworkSVG /></div>
                <PageLink href="/contact" className="text-base font-semibold py-6">Data visualization</PageLink>
                <p className="leading-loose py-4">Telling stories with data. We emphasize communication and visualization of results which may include interactive dashboards and/or automated reporting systems.</p>
                <PageLink href="/contact" className="text-sm font-normal py-6">learn more...</PageLink>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="bg-white w-full px-4 pt-0 md:pt-24 pb-2 md:pb-10 text-center flex flex-col ">
            <p className="pt-2 md:text-lg font-thin text-grey-dark">We work with state-of-the-art tools for model devlopment.</p>
          </div>
          <div className="w-full px-4 py-2 md:pb-12 text-center flex flex-col ">
            <div className="flex flex-wrap justify-between w-full">

              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.python.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.scrapy.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.scikit.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.neo4j.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-4 lg:pt-2 w-1/2 md:w-1/5">
                <Img fluid={props.data.tensorflow.childImageSharp.fluid} alt="" className="m-4" />
              </div>

              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.pytorch.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.keras.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.postgresql.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.spacy.childImageSharp.fluid} alt="" className="m-4" />
              </div>
              <div className="p-6 lg:p-4 w-1/2 md:w-1/5">
                <Img fluid={props.data.gensim.childImageSharp.fluid} alt="" className="m-4" />
              </div>

            </div>
          </div>
        </Section>
        <Section className="bg-very-lightest-grey border-solid border border-very-grey-light">
          <div id="example_projects" className="w-full px-0 py-8 md:py-24 text-center flex flex-col ">
            <H2 className="font-medium">Example projects</H2>
            <div className="flex flex-wrap justify-between m-0 pt-12">
              <div className="w-full xl:w-1/2 p-4 min-w-sm">
                <div className="flex flex-col flex-1 h-full bg-white border border-solid border-very-grey-light rounded-lg text-left p-10">
                  <p className="leading-loose py-4 min-w-200px">
                    <b>Evaluation of the International Disability Alliance (<acronym title="IDA is an alliance of organisations that advocate at the United Nations for a more inclusive global environment for persons with disabilities and their organisations. ">IDA</acronym>). </b>
                    A central challenge in the evaluation of IDA was to determine the importance of IDA as a
                    facilitator of its international network of peer organisations. To this effect,
                    we developed a web crawler that collect unstructured data (from web pages and social media platforms) on key topics,
                    as well as references to other network peers. The collected data was analysed with graph software that scrutinised
                    the connectivity of the network and hence estimated IDA’s centrality in the network. This aided in the
                    ambition to triangulated tentative findings as well as to generate deeper insights into the network.
                  </p>
                  <div className="w-full text-center overflow-hidden">

                    {/* <Img className="" fixed={props.data.datacollection.childImageSharp.fixed} alt="" /> */}
                    <iframe src='https://public.flourish.studio/visualisation/806882/embed' title='graph' frameborder='0' scrolling='no' style={{ "width": "100%", "height": "300px" }}></iframe>
                  </div>
                </div>
              </div>
              <div className="w-full xl:w-1/2 p-4 min-w-sm">
                <div className="flex flex-col flex-1 h-full bg-white border border-solid border-very-grey-light rounded-lg text-left p-10 ">
                  <p className="leading-loose py-4 min-w-200px">
                    <b>Systematic approach for processing peer reporting</b> in Ford
                    Foundation’s <acronym title="The Build programme is a five-year initiative that is expected to invest one billion USD in 300 global social justice organizations and their long-term capacity.">BUILD programme</acronym>.
                    We developed an approach for systematic processing of annual reports, as well as to map the content of the reports to Ford Foundation 's objectives and strategies.
                    For this purpose we utilized state of the art natural language processing (NLP) techniques,
                    which enabled us to quantitatively process a large amount of reports. The analytical outcome gave good estimations of
                    the aggregated performance of the programme as well as to highlight the extent of peer activity under
                    various thematic and strategic areas.
                  </p>
                  <div className="w-full text-center overflow-hidden">
                    {/* <Img className="" fluid={props.data.spiderwebfluid.childImageSharp.fluid} alt="" /> */}
                    <iframe src='https://public.flourish.studio/visualisation/838115/embed' title='worldmap' frameborder='0' scrolling='no' style={{ width: '100%', height: '350px' }}></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full p-4">
              <div className="md:flex md:flex-col w-full bg-white border border-solid border-very-grey-light rounded-lg text-left p-10 ">
                <p className="leading-loose py-4">
                  <b>Evaluation of IBON International and CSO Partnership for
                    Development Effectiveness (<acronym title="The purpose of the CPDE was to advance the implementation of an
                  effective development cooperation framework and to increase the focus on the implementation of Agenda 2030.">CPDE</acronym>). </b> <br></br>
                  We were procured to collect unstructured data from web pages and social media accounts from a large international network
                  of civil society organisations. The evaluation needed insights into how the network communicated and collaborated on
                  topics of key importance for the evaluation. To this end we developed a web crawler which was able to
                  collect data from how various organisations on their communication of key topics as illustrated in the graph below.                </p>
                <div className="w-full text-center overflow-hidden">
                  {/* <Img className="" fluid={props.data.reporting.childImageSharp.fluid} alt="" /> */}
                  <iframe src='https://public.flourish.studio/visualisation/838064/embed' title="ibon" frameborder='0' scrolling='no' style={{ width: '100%', height: '500px' }}></iframe>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section className="bg-white  border-solid border border-very-grey-light">
          <div id="media" className="w-full px-4 py-5 md:py-24 text-center flex flex-col ">
            {/* <H2 className="font-medium text-left">Tweets</H2> <div className="font-medium text-left"> by @davconsulting</div> */}
            <div className="flex">
              <div className="flex-1"></div>
              <div className="w-full sm:w-3/4 flex-none">
                <TwitterFeed />
              </div>
              <div className="flex-1"></div>
            </div>
          </div>
        </Section>
        <Section className="bg-very-lightest-grey  border-solid border border-very-grey-light">
          <div id="about" className="w-full px-4 py-8 md:py-24 text-center flex flex-col ">
            <H2 className="font-medium ">About us </H2>

            <div className="text-grey-dark text-lg font-thin leading-normal py-6">
              We're a Stockholm based company working with data science. Our purpose is to facilitate and strengthen our clients’ analytical performance through data-driven processes and corresponding insights. We strive to facilitate and strengthen our clients’ analytical performance that can improve operations and their overall efficiency. Our consultants have extensive experiences with work within academia as well as in the private sector during the last 15 years.

            </div>
            <p className="py-8 md:text-lg font-thin text-grey-dark">We deliver customized solutions for private companies, NGO's and government institutions.</p>

            <div className="flex flex-wrap justify-between w-full">
              <div className="px-24 sm:px-8 pt-12 w-full pb-10 sm:w-1/4">
                <div style={{ maxHeight: '120px' }}>
                  <Img fluid={props.data.ford.childImageSharp.fluid} style={{ maxHeight: '100px' }} imgStyle={{ objectFit: 'contain' }} alt="" />
                </div>
              </div>
              <div className="px-24 sm:px-8 py-10 w-full sm:w-1/4">
                <div style={{ maxHeight: '120px' }}>
                  <Img fluid={props.data.niras.childImageSharp.fluid} style={{ maxHeight: '100px' }} imgStyle={{ objectFit: 'contain' }} alt="" />
                </div>
              </div>
              <div className="px-24 sm:px-8 py-10 w-full sm:w-1/4">
                <div style={{ maxHeight: '120px' }}>
                  <Img fluid={props.data.sis.childImageSharp.fluid} style={{ maxHeight: '100px' }} imgStyle={{ objectFit: 'contain' }} alt="" />
                </div>
              </div>
              <div className="px-24 sm:px-8 pt-8 pb-10 w-full sm:w-1/4">
                <div style={{ maxHeight: '120px' }}>
                  <Img fluid={props.data.ida.childImageSharp.fluid} style={{ maxHeight: '100px' }} imgStyle={{ objectFit: 'contain' }} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section className="bg-very-light-grey  border-solid border border-very-grey-light">
          <div id="story" className=" w-full px-4 py-8 md:py-24 text-center flex flex-col ">
            <H2 className="font-medium py-2">Our Story </H2>
            <div className="flex flex-wrap pt-6 py-4 relative">
              <div className="pr-6">
                <Img fixed={props.data.founders.childImageSharp.fixed} style={{ borderRadius: '20px' }} alt="" />
              </div>

              <Hotspot top="130px" left="0px" width="65px" height="235px"></Hotspot>
              <div className="w-full  md:w-1/2 lg:w-3/5 py-2 text-left text-very-grey-dark text-md font-thin leading-normal">

                <b>Gustav Engström</b> <br></br>
                Data Scientist/Consultant <br />
                Ph.D. in economics from Stockholm University <br />
                10+ years of mathematics and statistical research and consulting <br /><br />
                {/* Python, Javascript, SQL, Stata - <br /><br /> */}

                Gustav has worked as an economics researcher for the past 7 years.
                His research has mainly focused on areas related to the economics of climate change, tipping points, development and urban economics.
                He has co-authored 15+  articles published in top level academic journals including Science and Nature.
                Currently he is engaged in data science services with a focus on data analysis, statistics and natural language processing.
                Gustav has experience with a wide variety of approaches for data collection and analysis.
                Apart from a high degree of knowledge in mathematics, statistics and inferential techniques his expertise covers areas such as machine learning,
                natural language processing, web-crawling, network analysis and web development.
                He thrives on open source solutions and the potential for automation off mundane processes using present state of the art technology.

              </div>
              <Hotspot top="170px" left="65px" width="80px" height="195px"></Hotspot>
              <div className="w-full md:w-1/2 lg:w-3/5 py-2 text-left text-very-grey-dark text-md font-thin leading-normal">
                <b>Jonas Norén</b> <br></br>

                CEO/Data Scientist/Consultant <br />
                M.Sc Political Science and Economics <br />
                15+ years of evaluation, monitoring and strategy consulting <br /><br />
                {/* Python, Excel, SQL  <br /> */}

                Jonas has worked with evaluations, strategy, capacity building, research, statistics and associated analysis for more than 10 years.
                He has mainly worked within the fields of private sector development and international development assistance.
                Currently he is primarily engaged in data science services with bearing on data driven approaches for collection, analysis and
                presentation of data. Jonas is experienced with different techniques and tools for data collection and analysis,
                which includes techniques for case studies, electronic survey systems, web crawling, network analysis,
                text analysis/mining and data storage/retrieval (SQL). Jonas is also experienced in launching and managing fully
                and/or semi-fully data-driven analytical IT-based systems that facilitates and drastically reduces time for data processing.

              </div>
              <span className="w-full md:w-1/2 lg:w-3/5  py-2 text-left text-very-grey-dark text-md font-thin leading-normal">
                Davcon AB was founded in 2019 by Jonas Norén and Gustav Engström. The formation of the company was based upon a common understanding
                that recent advancements and developments in data processing, analysis and overall handling of large amounts of data and/or documents
                which we utilized at a daily level were not being widely adopted by many organisations with which we regularly interacted.
                In particular, we noticed how many of our co-workers
                were engaging in repetitive tasks that we knew could be easily automized. Our mission has thus become to help companies and
                organisations "automate the boring stuff"!
              </span>
            </div>
          </div>
        </Section>
        <Footer />


      </div>
    </Layout>
  )
}


export default IndexPage;



// sis: 186 x 200, ellmount: 286 x 200, niras: 642 x 200, ford: 795 x 200

export const pageQuery = graphql`
query {
  desktop: file(relativePath: { eq: "images/bg_cropped.jpg" }) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 1920, grayscale: false) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  founders: file(relativePath: {eq: "images/founders.jpg" }) {
    childImageSharp {
      fixed(width: 280, height: 350, grayscale: true) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  spiderwebfluid: file(relativePath: {eq: "images/davcon_ford.png" }) {
    childImageSharp {
      fluid(maxWidth: 300, maxHeight: 210) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  datacollection: file(relativePath: {eq: "images/ida_circular.png" }) {
    childImageSharp {
      fixed(width: 350, height: 240) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  reporting: file(relativePath: {eq: "images/reporting.png" }) {
    childImageSharp {
      fluid(maxWidth: 215, maxHeight: 180) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  ida: file(relativePath: {eq: "images/ida.png" }) {
    childImageSharp {
      fluid(maxHeight: 120)  {
        ...GatsbyImageSharpFluid
      }
    }
  }
  sis: file(relativePath: {eq: "images/sis.png" }) {
    childImageSharp {
      fluid(maxHeight: 120, quality: 100)  {
        ...GatsbyImageSharpFluid
      }
    }
  }
  niras: file(relativePath: {eq: "images/niras.png" }) {
    childImageSharp {
      fluid(maxHeight: 120)  {
        ...GatsbyImageSharpFluid
      }
    }
  }
  ford: file(relativePath: {eq: "images/ford.png" }) {
    childImageSharp {
      fluid(maxHeight: 120) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  python: file(relativePath: {eq: "images/python-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  scikit: file(relativePath: {eq: "images/scikit-learn-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  scrapy: file(relativePath: {eq: "images/scrapy-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  neo4j: file(relativePath: {eq: "images/neo4j-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tensorflow: file(relativePath: {eq: "images/tensorflow.png" }) {
    childImageSharp {
      fluid(maxWidth: 120, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  keras: file(relativePath: {eq: "images/keras-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  pytorch: file(relativePath: {eq: "images/pytorch-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  postgresql: file(relativePath: {eq: "images/postgresql-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  spacy: file(relativePath: {eq: "images/spacy-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 100, grayscale: false) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  gensim: file(relativePath: {eq: "images/gensim_compact.png" }) {
    childImageSharp {
      fluid(maxWidth: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`


