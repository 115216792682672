import React from 'react'

const RocketSVG = () => (
  <svg width="44px" height="70px" viewBox="0 0 44 70" version="1.1">
    <defs>
      <path
        d="M10.5631075,0.227460575 C4.92250604,4.99165458 1.81950236,9.18512189 1.2540965,12.8078625 C0.688690644,16.4306031 0.37586448,28.0953089 0.315618011,47.8019799 C3.34823062,50.3271927 6.76406046,51.5897991 10.5631075,51.5897991 C14.3621546,51.5897991 17.7829532,50.3271927 20.8255034,47.8019799 C20.8973437,26.8139064 20.5343121,14.8123012 19.7364087,11.7971644 C18.9385053,8.78202764 15.8807383,4.92545968 10.5631075,0.227460575 Z"
        id="path-1"
      />
    </defs>
    <g
      id="Final"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="The-DataRobot-AI-Adoption-Path-2"
        transform="translate(-120.000000, -711.000000)"
      >
        <g id="Group-7" transform="translate(120.000000, 705.000000)">
          <g id="rocket" transform="translate(0.000000, 6.000000)">
            <g id="Path-3" transform="translate(11.742831, 1.693384)">
              <use id="Mask" fill="#FFFFFF" fill-rule="nonzero" />
              <path
                d="M14.5156741,2.87671233 C8.87507263,7.64090634 5.77206895,11.8343736 5.20666309,15.4571142 C4.64125723,19.0798548 4.64125723,30.7445607 5.20666309,50.4512317 C7.61362337,52.9764445 10.716627,54.2390509 14.5156741,54.2390509 C18.3147212,54.2390509 21.7355198,52.9764445 24.77807,50.4512317 C24.8499103,29.4631582 24.4868787,17.461553 23.6889753,14.4464162 C22.8910719,11.4312794 19.8333048,7.57471144 14.5156741,2.87671233 Z"
                fill="#C7E3FC"
                fill-rule="nonzero"
                opacity="0.6"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M20.8603145,31.7379587 L20.8603145,49.5914265 C20.8603145,50.1388864 21.2718928,50.5832471 21.7789642,50.5832471 C22.2860355,50.5832471 22.6976138,50.1388864 22.6976138,49.5914265 L22.6976138,31.7379587 C22.6976138,31.1904989 22.2860355,30.7461382 21.7789642,30.7461382 C21.2718928,30.7461382 20.8603145,31.1904989 20.8603145,31.7379587 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M25.9303562,17.4560142 C25.9303562,15.4239318 24.2822128,13.7714952 22.2556499,13.7714952 C20.229087,13.7714952 18.5809436,15.4240397 18.5809436,17.4560142 C18.5809436,19.4880967 20.229087,21.1405333 22.2556499,21.1405333 C24.2822128,21.1405333 25.9303562,19.4880967 25.9303562,17.4560142 Z"
              id="Path"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M22.2556499,19.2983277 C21.2423684,19.2983277 20.4182429,18.4721094 20.4182429,17.4560142 C20.4182429,16.440027 21.2422608,15.6137007 22.2556499,15.6137007 C23.2689313,15.6137007 24.0930568,16.439919 24.0930568,17.4560142 C24.0930568,18.4720015 23.2689313,19.2983277 22.2556499,19.2983277 Z"
              id="Path"
              fill="#FFFFFF"
              fill-rule="nonzero"
            />
            <path
              d="M12.386135,28.4406878 C11.0054059,28.5435357 10.1020728,28.8629654 9.67613571,29.398977 C9.25019861,29.9349886 8.90669766,30.5286448 8.64563287,31.1799455 L8.64563287,38.5019466 C4.23871239,40.7293495 1.87241783,42.3046222 1.54674918,43.2277648 C1.22108052,44.1509074 1.22108052,46.5620264 1.54674918,50.4611219 L12.386135,50.4611219 L12.386135,28.4406878 Z"
              id="Path-4-Copy"
              fill="#C7E3FC"
              fill-rule="nonzero"
              opacity="0.6"
            />
            <path
              d="M43.2155433,28.7671233 C41.8348142,28.8699712 40.9314811,29.1894009 40.505544,29.7254125 C40.0796069,30.261424 39.7361059,30.8550802 39.4750411,31.5063809 L39.4750411,38.8283821 C35.0681206,41.0557849 32.7018261,42.6310577 32.3761574,43.5542002 C32.0504888,44.4773428 32.0504888,46.8884618 32.3761574,50.7875573 L43.2155433,50.7875573 L43.2155433,28.7671233 Z"
              id="Path-4-Copy"
              fill="#C7E3FC"
              fill-rule="nonzero"
              opacity="0.6"
              transform="translate(37.673725, 39.777340) scale(-1, 1) translate(-37.673725, -39.777340) "
            />
            <path
              d="M12.1939658,50.3071986 C12.8024538,50.3071986 13.2963497,49.8241984 13.2963497,49.229134 L13.2963497,19.0421871 C13.2963497,18.9786377 13.3559066,13.2829492 17.0578649,8.4897119 C18.0313165,8.84548713 19.7929603,9.33922633 22.1158084,9.33922633 C24.4452452,9.33922633 26.201463,8.84333934 27.1693594,8.48642704 C30.8580111,13.2677884 30.9352671,18.9796485 30.9352671,19.0421871 L30.9352671,49.229134 C30.9352671,49.8241984 31.429163,50.3071986 32.037651,50.3071986 C32.646139,50.3071986 33.1400349,49.8241984 33.1400349,49.229134 L33.1400349,19.0421871 C33.1400349,18.7154702 33.0738893,10.983297 27.3048804,5.34269303 L22.8952156,1.0301818 C22.4641064,0.608709439 21.7673812,0.608709439 21.3364011,1.0301818 L16.9267364,5.34256669 C11.1577275,10.9831707 11.0915819,18.7153439 11.0915819,19.0420607 L11.0915819,49.2290076 C11.0915819,49.8243247 11.5854778,50.3071986 12.1939658,50.3071986 Z M22.1158084,3.31694729 L25.598401,6.72271178 C24.7572406,6.95555757 23.5677822,7.18309705 22.1158084,7.18309705 C20.6683563,7.18309705 19.4765724,6.95669464 18.6321823,6.72384885 L22.1158084,3.31694729 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M40.9908051,52.6173127 C40.4837337,52.6173127 40.0721554,53.0616735 40.0721554,53.6091333 L40.0721554,59.0341765 C40.0721554,59.5816363 40.4837337,60.0259971 40.9908051,60.0259971 C41.4978764,60.0259971 41.9094547,59.5816363 41.9094547,59.0341765 L41.9094547,53.6091333 C41.9094547,53.0616735 41.4978764,52.6173127 40.9908051,52.6173127 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M22.0686969,52.6171777 C16.1265958,52.6171777 12.6457824,48.9751168 12.6126236,48.940363 C12.4408007,48.7539244 12.2065358,48.6497792 11.9631201,48.6497792 L1.8577585,48.6497792 L1.8577585,43.6904438 C1.8577585,42.2809962 4.93539096,40.6444284 6.41354165,39.8578762 L8.69923846,38.6269633 C9.01069439,38.4583084 9.20727874,38.1151871 9.20727874,37.7392879 L9.20727874,30.9690545 C9.20727874,30.4225246 10.7812965,28.812554 11.2875065,28.812554 C11.7945779,28.812554 12.2061562,28.3681933 12.2061562,27.8207334 C12.2061562,27.2732736 11.7945779,26.8289129 11.2875065,26.8289129 C9.76801501,26.8289129 7.36987177,29.037481 7.36987177,30.6780008 L7.36987177,37.1263886 L5.60136621,38.0785735 C3.37908023,39.2609008 0.0203515264,41.0471775 0.0203515264,43.6905601 L0.0203515264,49.641716 C0.0203515264,50.1891759 0.431929827,50.6335366 0.939001184,50.6335366 L11.6067705,50.6335366 C12.6356625,51.6005994 16.2966961,54.6009351 22.0688046,54.6009351 C22.0713095,54.6081347 22.3535155,54.6344774 22.4602647,54.3926053 C22.567014,54.1507332 22.6396922,53.7786249 22.6385645,53.5812476 C22.6375188,53.3982303 22.57176,53.0079901 22.4602647,52.7817142 C22.3487695,52.5554382 22.0641842,52.6259906 22.0686969,52.6171777 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M21.8276995,52.6171777 C27.7698007,52.6171777 31.2506141,48.9751168 31.2837729,48.940363 C31.4555958,48.7539244 31.6898606,48.6497792 31.9332764,48.6497792 L42.0386379,48.6497792 L42.0386379,43.6904438 C42.0386379,42.2809962 38.9610055,40.6444284 37.4828548,39.8578762 L35.197158,38.6269633 C34.885702,38.4583084 34.6891177,38.1151871 34.6891177,37.7392879 L34.6891177,30.9690545 C34.6891177,30.4225246 33.1151,28.812554 32.6088899,28.812554 C32.1018185,28.812554 31.6902402,28.3681933 31.6902402,27.8207334 C31.6902402,27.2732736 32.1018185,26.8289129 32.6088899,26.8289129 C34.1283814,26.8289129 36.5265247,29.037481 36.5265247,30.6780008 L36.5265247,37.1263886 L38.2950302,38.0785735 C40.5173162,39.2609008 43.8760449,41.0471775 43.8760449,43.6905601 L43.8760449,49.641716 C43.8760449,50.1891759 43.4644666,50.6335366 42.9573952,50.6335366 L32.2896259,50.6335366 C31.260734,51.6005994 27.5997003,54.6009351 21.8275918,54.6009351 C21.8234996,54.6126971 21.5086429,54.1023657 21.5115838,53.5842901 C21.5142573,53.113336 21.8349856,52.6314067 21.8276995,52.6171777 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M37.7345609,52.6173127 C37.2274895,52.6173127 36.8159112,53.0616735 36.8159112,53.6091333 L36.8159112,68.9456141 C36.8159112,69.493074 37.2274895,69.9374347 37.7345609,69.9374347 C38.2416322,69.9374347 38.6532105,69.493074 38.6532105,68.9456141 L38.6532105,53.6091333 C38.6532105,53.0616735 38.2416322,52.6173127 37.7345609,52.6173127 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M33.8270678,52.6173127 C33.3199964,52.6173127 32.9084181,53.0616735 32.9084181,53.6091333 L32.9084181,63.8334151 C32.9084181,64.380875 33.3199964,64.8252357 33.8270678,64.8252357 C34.3341391,64.8252357 34.7457174,64.380875 34.7457174,63.8334151 L34.7457174,53.6091333 C34.7458251,53.0616735 34.3342468,52.6173127 33.8270678,52.6173127 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M2.24149887,52.6173127 C2.74857023,52.6173127 3.16014853,53.0616735 3.16014853,53.6091333 L3.16014853,59.0341765 C3.16014853,59.5816363 2.74857023,60.0259971 2.24149887,60.0259971 C1.73442751,60.0259971 1.32284921,59.5816363 1.32284921,59.0341765 L1.32284921,53.6091333 C1.32284921,53.0616735 1.73442751,52.6173127 2.24149887,52.6173127 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M6.14899193,52.6173127 C6.65606329,52.6173127 7.06764159,53.0616735 7.06764159,53.6091333 L7.06764159,68.9456141 C7.06764159,69.493074 6.65606329,69.9374347 6.14899193,69.9374347 C5.64192058,69.9374347 5.23034228,69.493074 5.23034228,68.9456141 L5.23034228,53.6091333 C5.23034228,53.0616735 5.64192058,52.6173127 6.14899193,52.6173127 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
            <path
              d="M9.73086059,52.6173127 C10.237932,52.6173127 10.6495103,53.0616735 10.6495103,53.6091333 L10.6495103,63.8334151 C10.6495103,64.380875 10.237932,64.8252357 9.73086059,64.8252357 C9.22378924,64.8252357 8.81221094,64.380875 8.81221094,63.8334151 L8.81221094,53.6091333 C8.81210328,53.0616735 9.22368158,52.6173127 9.73086059,52.6173127 Z"
              id="Shape"
              fill="#2D8FE2"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default RocketSVG
