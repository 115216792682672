import React from 'react'

const TodoSVG = () => (
  <svg width="52px" height="65px" viewBox="0 0 52 65" version="1.1">
    <g
      id="Final"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="The-DataRobot-AI-Adoption-Path-2"
        transform="translate(-746.000000, -965.000000)"
        fill-rule="nonzero"
      >
        <g id="Group-7-Copy" transform="translate(746.000000, 960.000000)">
          <g id="ds_prod" transform="translate(0.000000, 5.000000)">
            <path
              d="M8.93930602,1 L41.3793054,1 L41.3793054,9.75696251 L51,9.75696251 L51.8688403,59.9307439 C51.9070893,62.1395518 50.1475038,63.9611513 47.938696,63.9994004 C47.9156127,63.9998001 47.8925265,64 47.8694399,64 L8.93930602,64 C6.73016702,64 4.93930602,62.209139 4.93930602,60 L4.93930602,5 C4.93930602,2.790861 6.73016702,1 8.93930602,1 Z M9,20 C8.44771525,20 8,20.4477153 8,21 L8,30 C8,30.5522847 8.44771525,31 9,31 L18,31 C18.5522847,31 19,30.5522847 19,30 L19,21 C19,20.4477153 18.5522847,20 18,20 L9,20 Z M9,34 C8.44771525,34 8,34.4477153 8,35 L8,44 C8,44.5522847 8.44771525,45 9,45 L18,45 C18.5522847,45 19,44.5522847 19,44 L19,35 C19,34.4477153 18.5522847,34 18,34 L9,34 Z M9,48 C8.44771525,48 8,48.4477153 8,49 L8,58 C8,58.5522847 8.44771525,59 9,59 L18,59 C18.5522847,59 19,58.5522847 19,58 L19,49 C19,48.4477153 18.5522847,48 18,48 L9,48 Z"
              id="Combined-Shape"
              fill="#C7E3FC"
              opacity="0.6"
            />
            <path
              d="M51.4982812,9.4371875 L42.3596875,0.29859375 C42.1758594,0.11375 41.9219531,0 41.640625,0 L5.078125,0 C2.27804687,0 0,2.27804687 0,5.078125 L0,59.921875 C0,62.7219531 2.27804687,65 5.078125,65 L37.578125,65 L46.71875,65 C49.5188281,65 51.796875,62.7219531 51.796875,59.921875 L51.796875,10.15625 C51.796875,9.87492188 51.683125,9.62101563 51.4982812,9.4371875 Z M42.65625,3.46734375 L48.3295312,9.140625 L42.65625,9.140625 L42.65625,3.46734375 Z M2.03125,59.921875 L2.03125,5.078125 C2.03125,3.39828125 3.39828125,2.03125 5.078125,2.03125 L40.625,2.03125 L40.625,10.15625 C40.625,10.7178906 41.08,11.171875 41.640625,11.171875 L49.765625,11.171875 L49.765625,59.46875 C49.765625,61.4017466 48.1986216,62.96875 46.265625,62.96875 L5.078125,62.96875 C3.39828125,62.96875 2.03125,61.6017188 2.03125,59.921875 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="23.34375"
              y="24.375"
              width="20.3125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="15.234375"
              y="5.078125"
              width="20.3125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="12.1875"
              y="9.140625"
              width="26.40625"
              height="2.03125"
              rx="1.015625"
            />
            <path
              d="M18.28125,29.453125 L9.140625,29.453125 L9.140625,21.328125 L14.21875,21.328125 L14.21875,19.296875 L8.125,19.296875 C7.564375,19.296875 7.109375,19.7508594 7.109375,20.3125 L7.109375,30.46875 C7.109375,31.0303906 7.564375,31.484375 8.125,31.484375 L19.296875,31.484375 C19.8575,31.484375 20.3125,31.0303906 20.3125,30.46875 L20.3125,24.375 L18.28125,24.375 L18.28125,29.453125 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <path
              d="M11.8899219,23.6569531 L10.4538281,25.0930469 L12.4850781,27.1242969 C12.683125,27.3223437 12.943125,27.421875 13.203125,27.421875 C13.463125,27.421875 13.723125,27.3223437 13.9211719,27.1242969 L20.0149219,21.0305469 L18.5788281,19.5944531 L13.203125,24.9701562 L11.8899219,23.6569531 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="23.34375"
              y="38.59375"
              width="20.3125"
              height="2.03125"
              rx="1.015625"
            />
            <path
              d="M18.28125,43.671875 L9.140625,43.671875 L9.140625,35.546875 L14.21875,35.546875 L14.21875,33.515625 L8.125,33.515625 C7.564375,33.515625 7.109375,33.9696094 7.109375,34.53125 L7.109375,44.6875 C7.109375,45.2491406 7.564375,45.703125 8.125,45.703125 L19.296875,45.703125 C19.8575,45.703125 20.3125,45.2491406 20.3125,44.6875 L20.3125,38.59375 L18.28125,38.59375 L18.28125,43.671875 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <path
              d="M11.8899219,37.8757031 L10.4538281,39.3117969 L12.4850781,41.3430469 C12.683125,41.5410937 12.943125,41.640625 13.203125,41.640625 C13.463125,41.640625 13.723125,41.5410937 13.9211719,41.3430469 L20.0149219,35.2492969 L18.5788281,33.8132031 L13.203125,39.1889062 L11.8899219,37.8757031 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="23.34375"
              y="52.8125"
              width="20.3125"
              height="2.03125"
              rx="1.015625"
            />
            <path
              d="M18.28125,57.890625 L9.140625,57.890625 L9.140625,49.765625 L14.21875,49.765625 L14.21875,47.734375 L8.125,47.734375 C7.564375,47.734375 7.109375,48.1883594 7.109375,48.75 L7.109375,58.90625 C7.109375,59.4678906 7.564375,59.921875 8.125,59.921875 L19.296875,59.921875 C19.8575,59.921875 20.3125,59.4678906 20.3125,58.90625 L20.3125,52.8125 L18.28125,52.8125 L18.28125,57.890625 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <path
              d="M11.8899219,52.0944531 L10.4538281,53.5305469 L12.4850781,55.5617969 C12.683125,55.7598437 12.943125,55.859375 13.203125,55.859375 C13.463125,55.859375 13.723125,55.7598437 13.9211719,55.5617969 L20.0149219,49.4680469 L18.5788281,48.0319531 L13.203125,53.4076562 L11.8899219,52.0944531 Z"
              id="Shape"
              fill="#2D8FE2"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="12.1875"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="16.25"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="20.3125"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="24.375"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="28.4375"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="32.5"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
            <rect
              id="Rectangle-path"
              fill="#2D8FE2"
              x="36.5625"
              y="13.203125"
              width="2.03125"
              height="2.03125"
              rx="1.015625"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default TodoSVG
