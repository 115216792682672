import React from 'react'

class TwitterFeed extends React.Component {
    componentDidMount() {
        if (typeof twttr !== 'undefined') {
            window.twttr.widgets.load(document.getElementById('twitter-timeline'));
        }
    }
    render() {
        return <a id="twitter-feed" className="twitter-timeline" data-height="500" data-width="100%" data-theme="white" href="https://twitter.com/davconsulting" > </a>
    }
}

export default TwitterFeed;